/* eslint-disable */
import Header from '../../../Header.vue'
import Footer from '../../../Footer.vue'
import UserLeft from '../../PersonUserLeft.vue'
import CheckInService from '../../../../api/CheckInService.js'

export default{
	name:'EditSecPerEmail',
	data(){
		return{
			userInfo:'',
			userEmail:'', //邮箱
			userCode:'', //验证码
			isGetCode:false,
			codeTime:0,
			sendCode:''
		}
	},
	methods:{
		GetCode(){ //获取验证码
			var that = this
			if(that.userEmail.trim() == ''){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入邮箱')
				return
			}else if(!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(that.userEmail.trim()))){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入正确格式邮箱')
				return
			}
			$('#userEmail input').removeClass('error')
			$('#userEmail .errorts').text('')
			CheckInService.SendValidCode(that.$UserID,that.userEmail.trim(),5,1).then(res=>{
				if(res.Tag == 1){
					that.sendCode = res.Description 
				}else{
					that.$alert('发送失败，请稍后重试', '提示', {
						confirmButtonText: '确定'
					});
				}
			})
			that.codeTime = 60
			that.isGetCode = true
			var setInterv = setInterval(function() {
				that.codeTime--;
				if (that.codeTime < 0) {
					that.codeTime = 60
					clearInterval(setInterv)
					that.isGetCode = false
				}
			}, 1000)
		},
		SaveEdit(){
			var that = this
			if(that.userEmail.trim() == ''){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入邮箱')
				return
			}else if(!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(that.userEmail.trim()))){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入正确格式邮箱')
				return
			}else if(that.userCode.trim() == ''){
				$('#userCode input').addClass('error')
				$('#userCode .errorts').text('请输入验证码')
				$('#userEmail input').removeClass('error')
				$('#userEmail .errorts').text('')
				return
			}else if(that.userCode.trim() != that.sendCode){
				$('#userCode input').addClass('error')
				$('#userCode .errorts').text('请输入正确验证码')
				$('#userEmail input').removeClass('error')
				$('#userEmail .errorts').text('')
				return
			}
			$('#userCode input').removeClass('error')
			$('#userCode .errorts').text('')
			$('#userEmail input').removeClass('error')
			$('#userEmail .errorts').text('')
			CheckInService.ValidCode(that.$UserID,that.userEmail.trim(),5,1,that.userCode.trim()).then(res=>{
				if(res.Tag == 1){
					CheckInService.UpdateAccount(that.$UserID,that.userEmail.trim(),1).then(msg=>{
						if(msg.Tag == 1){
							that.$router.go(-2)
						}else{
							that.$alert(msg.Message, '提示', {
								confirmButtonText: '确定'
							});
						}
					})
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		}
	},
	created(){
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
	}
}